import { mergeArrayFromTranslations } from "../misc/Helper";
import logoPath from "../../assets/ensign-logo.png";
import { Link } from "react-router-dom";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

export const Footer = (props) => {
  const footerData = props.footerLinksData.footer_info;
  const footerDataTranslation = mergeArrayFromTranslations(footerData);

  const firstColTitle = footerDataTranslation.filter(function (type) {
    return type.type === "1st_col_title";
  });
  const firstCol = footerDataTranslation.filter(function (type) {
    return type.type === "1st_col";
  });
  firstCol.sort(function (a, b) {
    return parseFloat(a.position) - parseFloat(b.position);
  });
  const secondColTitle = footerDataTranslation.filter(function (type) {
    return type.type === "2nd_col_title";
  });
  const secondCol = footerDataTranslation.filter(function (type) {
    return type.type === "2nd_col";
  });
  secondCol.sort(function (a, b) {
    return parseFloat(a.position) - parseFloat(b.position);
  });
  const thirdColTitle = footerDataTranslation.filter(function (type) {
    return type.type === "3rd_col_title";
  });
  const thirdCol = footerDataTranslation.filter(function (type) {
    return type.type === "3rd_col";
  });
  thirdCol.sort(function (a, b) {
    return parseFloat(a.position) - parseFloat(b.position);
  });
  const fourthTitle = footerDataTranslation.filter(function (type) {
    return type.type === "4th_col_title";
  });
  const fourthCol = footerDataTranslation.filter(function (type) {
    return type.type === "4th_col";
  });

  const fifthColTitle = footerDataTranslation.filter(function (type) {
    return type.type === "5th_col_title";
  });
  const fifthCol = footerDataTranslation.filter(function (type) {
    return type.type === "5th_col";
  });
  fifthCol.sort(function (a, b) {
    return parseFloat(a.position) - parseFloat(b.position);
  });

  const copyrightTitle = footerDataTranslation.filter(function (type) {
    return type.type === "copyright_title";
  });
  const copyright = footerDataTranslation.filter(function (type) {
    return type.type === "copyright";
  });
  copyright.sort(function (a, b) {
    return parseFloat(a.position) - parseFloat(b.position);
  });

  return (
    <div>
      <div className="pt-5 px-4">
        <div className="relative justify-center largephone:flex largephone:flex-row largephone:grid largephone:grid-cols-6 tablet:grid-cols-7 mx-auto">
          <div className="hide_footer_logo">
            <ul className="pt-8 list-none largephone:flex largephone:flex-column">
              <img src={logoPath} alt="logo" width={140} height={100} />
            </ul>
          </div>
          <div>
            <ul className="pt-8 list-none largephone:flex largephone:flex-column">
              <div className="largephone:px-4">
                <li>
                  <Link to={firstColTitle[0].url}>
                    <span className="font-medium phone:text-lg text-base hover:underline text-primary-B100 hover:text-secondary-B300">
                      {firstColTitle[0].name}
                    </span>
                  </Link>
                </li>
                <li className="pt-4">
                  <Link to={firstCol[0].url}>
                    <span className="font-medium phone:text-lg text-base hover:underline text-primary-B100 hover:text-secondary-B300">
                      {firstCol[0].name}
                    </span>
                  </Link>
                </li>
                <li className="pt-4">
                  <Link to={firstCol[1].url}>
                    <span className="font-medium phone:text-lg text-base hover:underline text-primary-B100 hover:text-secondary-B300">
                      {firstCol[1].name}
                    </span>
                  </Link>
                </li>
                <li className="pt-4">
                  <Link to={firstCol[2].url}>
                    <span className="font-medium phone:text-lg text-base hover:underline text-primary-B100 hover:text-secondary-B300">
                      {firstCol[2].name}
                    </span>
                  </Link>
                </li>
              </div>
            </ul>
          </div>
          <div>
            <ul className="pt-8 list-none largephone:px-5 largephone:flex largephone:flex-column">
              <div className="">
                <h5 className="font-medium phone:text-lg text-base">
                  {secondColTitle[0].name}
                </h5>
                <div className="font-light break-normal">
                  {secondCol.map((highlight, index) => (
                    <li className="py-2 phone:text-base text-sm" key={index}>
                      <Link to={highlight.url}>
                        <span className="hover:underline text-primary-B100 hover:text-secondary-B300">
                          {highlight.name}
                        </span>
                      </Link>
                    </li>
                  ))}
                </div>
              </div>
            </ul>
          </div>
          <div>
            <ul className="pt-8 list-none largephone:px-5 largephone:flex largephone:flex-column">
              <div className="">
                <h5 className="font-medium phone:text-lg text-base">
                  {thirdColTitle[0].name}
                </h5>
                <div className="font-light">
                  {thirdCol.map((about, index) => (
                    <li className="py-2 phone:text-base text-sm" key={index}>
                      <Link to={about.url}>
                        <span className="hover:underline text-primary-B100 hover:text-secondary-B300">
                          {about.name}
                        </span>
                      </Link>
                    </li>
                  ))}
                </div>
              </div>
            </ul>
          </div>
          <div>
            <ul className="pt-8 list-none largephone:px-5 largephone:flex largephone:flex-column">
              <div className="">
                <h5 className="font-medium phone:text-lg text-base">
                  {fourthTitle[0].name}
                </h5>
                <div className="font-light">
                  {fourthCol.map((about, index) => (
                    <li className="py-2 phone:text-base text-sm" key={index}>
                      <Link to={about.url}>
                        <span className="hover:underline text-primary-B100 hover:text-secondary-B300">
                          {about.name}
                        </span>
                      </Link>
                    </li>
                  ))}
                </div>
              </div>
            </ul>
            {/* <ul className="pt-8 list-none largephone:px-5 largephone:flex largephone:flex-column">
              <div className="">
                <h5 className="font-medium phone:text-lg text-base">
                  {fourthTitle[0].name}
                </h5>
                <div className="font-light break-normal">
                  <button className="hover:underline" onClick={onOpen}>
                    <p className=" py-2 text-left phone:text-base text-sm">
                      {' '}
                      {fourthCol[0].name}
                    </p>
                  </button>
                </div>
              </div>
            </ul> */}
          </div>
          <div className="largephone:col-span-2">
            <ul className="pt-8 list-none largephone:px-5">
              <Link to={fifthColTitle[0].url}>
                <span className="font-medium phone:text-lg text-base hover:underline">
                  {fifthColTitle[0].name}
                </span>
              </Link>
              <div>
                <Accordion
                  defaultIndex={[0]}
                  allowMultiple
                  className="largephone:col-span-2"
                >
                  {fifthCol.map((contactUs, index) => (
                    <AccordionItem key={index}>
                      <h5>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            {contactUs.name}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h5>
                      <AccordionPanel className="font-light text-sm">
                        <div
                          className="largephone:mr-4 pb-4"
                          dangerouslySetInnerHTML={{
                            __html: contactUs.article_body,
                          }}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </ul>
          </div>
          {/* <div className="largephone:col-span-2">
            <ul className="pt-8 list-none largephone:px-5 largephone:flex largephone:flex-column">
              <div className="">
                <h5 className="font-medium phone:text-lg text-base">
                  {fifthColTitle[0].name}
                </h5>
                <div className="font-light">
                  {fifthCol.map((about, index) => (
                    <li className="py-2 phone:text-base text-sm" key={index}>
                      <Link to={about.url}>
                        <span className="hover:underline">{about.name}</span>
                      </Link>
                    </li>
                  ))}
                </div>
              </div>
            </ul>
          </div> */}
        </div>
      </div>
      <div className="py-8 pb-8 mx-auto largephone:flex largephone:flex-auto gap-4 flex-wrap">
        <div className="flex-auto ml-auto">{copyrightTitle[0].name}</div>
        <div className="flex justify-end">
          {copyright.map((privacy, index) => (
            <div className=" border-l-2 px-4" key={index}>
              <Link to={privacy.url}>
                <span className="hover:underline">{privacy.name}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
