import React, { useEffect } from "react";
import { HalfSizeBanner } from "../../banners/HalfSizedBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { Footer } from "../../misc/Footer";
import { BodyAndImage } from "../../card/BodyAndImage";
import { CardWithImage } from "../../card/CardWithImage";

import CombatRansomwareBanner from "../../../assets/use-case/combat-ransomware/ENSIGN-BANNER-RANSOMWARE.jpg";
import CombatRansomwareSmallBanner from "../../../assets/use-case/combat-ransomware/ransomware (1).jpg";
import CombatRansomwareMitreRaas from "../../../assets/use-case/combat-ransomware/MITRE-RAAS.png";
import CombatRansomwareRAASA from "../../../assets/use-case/combat-ransomware/RAASA.png";

import {
  COMBATRANSOMWARE,
  COMBATRANSOMWARE_CARD_TITLE,
  COMBATRANSOMWARE_CARD_BODY,
  COMBATRANSOMWARE_INFO,
  COMBATRANSOMWARE_INFO_2,
  COMBATRANSOMWARE_CARD,
  COMBATRANSOMWARE_RASS,
  COMBATRANSOMWARE_INFO_3,
  COMBATRANSOMWARE_FIGURE,
  COMBATRANSOMWARE_PROTECT,
  COMBATRANSOMWARE_INFO_4,
  COMBATRANSOMWARE_INFO_5,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../../constant/text";

const CombatRansomware = () => {
  useEffect(() => {
    document.title = COMBATRANSOMWARE;
  }, []);
  return (
    <>
      <HalfSizeBanner
        name={COMBATRANSOMWARE}
        image={CombatRansomwareBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="flex body_font_size mx-4">{COMBATRANSOMWARE_INFO}</div>
        <div className="mx-4">
          <BodyAndImage
            name={COMBATRANSOMWARE_CARD_TITLE}
            body={COMBATRANSOMWARE_CARD_BODY}
            image={CombatRansomwareSmallBanner}
            imageFloat="float-right"
          />
        </div>
        <div className="flex body_font_size mx-4">
          {COMBATRANSOMWARE_INFO_2}
        </div>
        <div className="mx-4">
          <CardWithImage
            numInRow={4}
            rowIcons={COMBATRANSOMWARE_CARD}
            notToHaveBorder={true}
            centerChild={true}
          />
        </div>

        <div className="flex header_font_size mx-4">
          {COMBATRANSOMWARE_RASS}
        </div>
        <div className="flex body_font_size mx-4">
          {COMBATRANSOMWARE_INFO_3}
        </div>
        <img src={CombatRansomwareRAASA} alt={COMBATRANSOMWARE_RASS} />
        <img src={CombatRansomwareMitreRaas} alt={COMBATRANSOMWARE_RASS} />
        <div className="flex body_font_size mx-4">
          {COMBATRANSOMWARE_FIGURE}
        </div>
        <div className="flex body_font_size mx-4">
          {COMBATRANSOMWARE_INFO_4}
        </div>
        <div className="flex header_font_size mx-4">
          {COMBATRANSOMWARE_PROTECT}
        </div>
        <div className="flex body_font_size mx-4">
          {COMBATRANSOMWARE_INFO_5}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default CombatRansomware;
