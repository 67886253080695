/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

export const CardWithMultipleImage = (props) => {
  const numOfCol =
    props.numInRow === 1
      ? "tablet:grid-cols-1"
      : props.numInRow === 2
      ? "tablet:grid-cols-2"
      : props.numInRow === 3
      ? "tablet:grid-cols-3"
      : props.numInRow === 4
      ? "tablet:grid-cols-4"
      : null;

  const [hoverColorChange, setHoverColorChange] = useState(false);

  function handleMouseEnter() {
    setHoverColorChange(true);
  }

  function handleMouseLeave() {
    setHoverColorChange(false);
  }

  return (
    <div
      style={{ borderColor: props.color }}
      className={`h-full hover:border-4 rounded-2xl cursor-pointer`}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
    >
      <div className="p-4 justify-center">
        <p
          style={hoverColorChange ? { color: props.color } : { color: "black" }}
          className={`text-center font-bold text-3xl widescreen:text-4xl pb-4`}
        >
          {props.title}
        </p>
        <span className="text-gray-700">
          <div className={`grid ${numOfCol} justify-items-center`}>
            {props.cardDetails.map((content, index) => (
              <div className="mx-auto" key={index}>
                <img
                  unoptimized={true}
                  src={content.image}
                  alt={content.name}
                  className="block mx-auto"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
                <p className="widescreen:text-base text-sm font-medium mb-2 text-center">
                  {content.name}
                </p>
              </div>
            ))}
          </div>
        </span>
      </div>
    </div>
  );
};
