import React, { useEffect, useState } from "react";
import { BottomBanner } from "../banners/BottomBanner";
import { Footer } from "../misc/Footer";
import { PageBanner } from "../banners/PageBanner";
import HomepageBanner from "../../assets/homepage/1920x501-CTI-HOMEPAGE-BANNER.jpg";
import HomepageConsult from "../../assets/homepage/ENSIGN - CONSULT.jpg";
import HomepageDesign from "../../assets/homepage/ENSIGN - DESIGN _ BUILD.jpg";
import HomepageOperate from "../../assets/homepage/ENSIGN - OPERATE.jpg";
import HomepageResponse from "../../assets/homepage/ENSIGN - RESPONSE.jpg";

import {
  ENSIGNINFOSECURITY,
  HOMEPAGE_BANNER_TITLE,
  HOMEPAGE_BANNER_BODY,
  FIND_OUT_MORE,
  HOMEPAGE_TITLE,
  HOMEPAGE_HEADER,
  BOTTOM_BANNER_DETAILS,
  HOMEPAGE_CARD_TOP_1,
  HOMEPAGE_CARD_TOP_2,
  HOMEPAGE_CARD_TOP_3,
  HOMEPAGE_CARD_TOP_4,
  HOMEPAGE_CARD_DETAILS_1,
  HOMEPAGE_CARD_DETAILS_2,
  HOMEPAGE_CARD_DETAILS_3,
  HOMEPAGE_CARD_DETAILS_4,
  HOMEPAGE_IMAGE_CARD,
  FOOTER_DETAILS,
  RESOURCESDATA,
} from "../../constant/text";
import { CardWithMultipleImage } from "../card/CardWithMultipleImage";
import CardCarousel from "../card/CardCarousel";
import CardCarouselWeb from "../card/CardCarouselWeb";
import { CardWithNormalImage } from "../card/CardWithNormalImage";
import { ArticlesInsightsCard } from "../card/ArticlesInsightsCard";

const Main = () => {
  useEffect(() => {
    document.title = ENSIGNINFOSECURITY;
  }, []);

  const [currentServicesTab, setCurrentServicesTab] = useState("CONSULT");
  const handleServicesClick = (tab) => {
    setCurrentServicesTab(tab);
  };
  const toggleServicesTab = () => {
    switch (currentServicesTab) {
      case "CONSULT":
        return (
          <>
            <div className="flex w-full widescreen:mx-auto widescreen:container mx-0 my-4">
              <div className="w-4/12">
                <img src={HomepageConsult} alt="CONSULT" />
              </div>

              <div className="flex flex-col pr-4 pl-16 w-8/12">
                <div className="card_header_font_size">CONSULT</div>
                <div className="card_body_font_size">
                  Harness our expertise to fortify your digital defences and
                  safeguard your valuable assets.
                </div>
                <div className="h-full">
                  <CardCarouselWeb
                    rowIcons={HOMEPAGE_CARD_DETAILS_1}
                    backgroundColor="bg-slate-200"
                    key={currentServicesTab}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "DESIGN & BUILD":
        return (
          <>
            <div className="flex w-full widescreen:mx-auto widescreen:container mx-0 my-4">
              <div className="w-4/12">
                <img src={HomepageDesign} alt="DESIGN & BUILD" />
              </div>

              <div className="flex flex-col pr-4 pl-16 w-8/12">
                <div className="card_header_font_size">DESIGN & BUILD</div>
                <div className="card_body_font_size">
                  Create robust and resilient cybersecurity solutions tailored
                  to your unique business needs.
                </div>
                <div className="h-full">
                  <CardCarouselWeb
                    rowIcons={HOMEPAGE_CARD_DETAILS_2}
                    backgroundColor="bg-slate-200"
                    key={currentServicesTab}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "OPERATE":
        return (
          <>
            <div className="flex w-full widescreen:mx-auto widescreen:container mx-0 my-4">
              <div className="w-4/12">
                <img src={HomepageOperate} alt="OPERATE" />
              </div>

              <div className="flex flex-col pr-4 pl-16 w-8/12">
                <div className="card_header_font_size">OPERATE </div>
                <div className="card_body_font_size">
                  Ensure round-the-clock protection for your business.
                </div>
                <CardCarouselWeb
                  rowIcons={HOMEPAGE_CARD_DETAILS_3}
                  backgroundColor="bg-slate-200"
                  key={currentServicesTab}
                />
              </div>
            </div>
          </>
        );
      case "RESPOND":
        return (
          <>
            <div className="flex w-full widescreen:mx-auto widescreen:container mx-0 my-4">
              <div className="w-4/12">
                <img src={HomepageResponse} alt="RESPOND" />
              </div>
              <div className="flex flex-col pr-4 pl-16 w-8/12">
                <div className="card_header_font_size">RESPOND </div>
                <div className="card_body_font_size">
                  Rapidly respond to cyber incidents, minimising damage and
                  restoring normalcy.
                </div>
                <CardCarouselWeb
                  rowIcons={HOMEPAGE_CARD_DETAILS_4}
                  backgroundColor="bg-slate-200"
                  key={currentServicesTab}
                />
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <PageBanner
        name={HOMEPAGE_BANNER_TITLE}
        body={HOMEPAGE_BANNER_BODY}
        image={HomepageBanner}
        titleColor="text-white"
        hasButton={true}
        url="/resources/98"
        url_string={FIND_OUT_MORE}
      />
      <div className="px-8 max-w-screen-xl mx-auto flex-wrap text-center rounded-xl">
        <div>
          <div className="card_header_font_size pt-8">{HOMEPAGE_HEADER}</div>
        </div>
        <div className="unhide_when_mobile">
          <div className="mt-8">
            <div
              className="font-semibold card_body_font_size border-b-2 border-y-primary-G100 py-6"
              dangerouslySetInnerHTML={{ __html: HOMEPAGE_TITLE[0] }}
            />
            <div
              className="font-semibold card_body_font_size border-b-2 border-y-primary-G100 py-6"
              dangerouslySetInnerHTML={{ __html: HOMEPAGE_TITLE[1] }}
            />
            <div
              className="font-semibold card_body_font_size py-4"
              dangerouslySetInnerHTML={{ __html: HOMEPAGE_TITLE[2] }}
            />
          </div>
        </div>
        <div className="hide_when_mobile ">
          <div className="grid grid-cols-3 pt-8">
            <div
              className="font-semibold card_body_font_size border-r-2 border-y-secondary-G300 px-12"
              dangerouslySetInnerHTML={{ __html: HOMEPAGE_TITLE[0] }}
            />
            <div
              className="font-semibold card_body_font_size border-r-2 border-y-secondary-G300 px-12"
              dangerouslySetInnerHTML={{ __html: HOMEPAGE_TITLE[1] }}
            />
            <div
              className="font-semibold card_body_font_size px-12"
              dangerouslySetInnerHTML={{ __html: HOMEPAGE_TITLE[2] }}
            />
          </div>
        </div>
      </div>
      <div className="slant-line-container">
        <hr className="slant-line-top" />
        <hr className="slant-line-bottom" />
      </div>
      <div className="hide_when_mobile">
        <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto mx-4 tablet:flex tablet:flex-auto gap-4 flex-wrap">
          <div
            className="flex-1 mx-2"
            onClick={() => handleServicesClick("CONSULT")}
          >
            <CardWithMultipleImage
              color="rgb(250 204 21)"
              numInRow={2}
              title="CONSULT"
              cardDetails={HOMEPAGE_CARD_TOP_1}
            />
          </div>
          <div
            className="flex-1 mx-2"
            onClick={() => handleServicesClick("DESIGN & BUILD")}
          >
            <CardWithMultipleImage
              color="rgb(250 204 21)"
              numInRow={3}
              title="DESIGN & BUILD"
              cardDetails={HOMEPAGE_CARD_TOP_2}
            />
          </div>
          <div
            className="flex-1 mx-2"
            onClick={() => handleServicesClick("OPERATE")}
          >
            <CardWithMultipleImage
              color="rgb(250 204 21)"
              numInRow={3}
              title="OPERATE"
              cardDetails={HOMEPAGE_CARD_TOP_3}
            />
          </div>
          <div
            className="flex-1 mx-2"
            onClick={() => handleServicesClick("RESPOND")}
          >
            <CardWithMultipleImage
              color="rgb(250 204 21)"
              numInRow={2}
              title="RESPOND"
              cardDetails={HOMEPAGE_CARD_TOP_4}
            />
          </div>
        </div>
        {toggleServicesTab()}
      </div>
      <div className="unhide_when_mobile">
        <div className="mx-4 my-4">
          <div className="card_header_font_size">CONSULT</div>
          <div className="card_body_font_size">
            Harness our expertise to fortify your digital defences and safeguard
            your valuable assets.
          </div>
        </div>
        <CardCarousel
          rowIcons={HOMEPAGE_CARD_DETAILS_1}
          backgroundColor="bg-slate-200"
        />
        <div>
          <div className="mx-4 my-4">
            <div className="card_header_font_size">DESIGN & BUILD</div>
            <div className="card_body_font_size">
              Create robust and resilient cybersecurity solutions tailored to
              your unique business needs.
            </div>
          </div>
          <CardCarousel
            rowIcons={HOMEPAGE_CARD_DETAILS_2}
            backgroundColor="bg-slate-200"
          />
        </div>

        <div>
          <div className="mx-4 my-4">
            <div className="card_header_font_size">OPERATE</div>
            <div className="card_body_font_size">
              Ensure round-the-clock protection for your business.
            </div>
          </div>
          <CardCarousel
            rowIcons={HOMEPAGE_CARD_DETAILS_3}
            backgroundColor="bg-slate-200"
          />
        </div>
        <div>
          <div className="mx-4 my-4">
            <div className="card_header_font_size">RESPOND</div>
            <div className="card_body_font_size">
              Rapidly respond to cyber incidents, minimising damage and
              restoring normalcy.
            </div>
          </div>
          <CardCarousel
            rowIcons={HOMEPAGE_CARD_DETAILS_4}
            backgroundColor="bg-slate-200"
          />
        </div>
      </div>
      <div className="slant-line-container">
        <hr className="slant-line-top" />
        <hr className="slant-line-bottom" />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <CardWithNormalImage numInRow={4} cardData={HOMEPAGE_IMAGE_CARD} />
      </div>
      <div className="py-4 tablet:max-w-screen-2xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="header_font_size mx-4">Resources</div>

        <ArticlesInsightsCard numInRow={3} cardDetails={RESOURCESDATA} />
      </div>{" "}
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default Main;
