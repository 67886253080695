import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faStore,
  faLightbulb,
  faGear,
  faBars,
  faFolderOpen,
  faSuitcase,
  faCircleInfo,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const iconMap = {
  faHome,
  faStore,
  faLightbulb,
  faGear,
  faBars,
  faFolderOpen,
  faSuitcase,
  faCircleInfo,
  faPhone,
};

function getFontAwesomeIcon(iconName) {
  return iconMap[iconName] || null;
}

function GenerateFontAwesomeIcon({ iconName = "", ...rest }) {
  const icon = getFontAwesomeIcon(iconName);
  return icon ? (
    <FontAwesomeIcon className="font_awesome_size" icon={icon} {...rest} />
  ) : null;
}

export { GenerateFontAwesomeIcon };

export function mergeArrayFromTranslations(arr) {
  const result = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].translations[0]) {
      const arrSlice = arr[i].translations[0];
      result.push(arrSlice);
    }
  }

  return result;
}
