const ElementVariant = {
  primary: "primary",
  secondary: "secondary",
  tertiary: "tertiary",
  danger: "danger",
  success: "success",
  warning: "warning",
  info: "info",
};

const ElementTextClassnames = {
  primary: "text-text-primary",
  secondary: "text-text-alt",
  tertiary: "text-text-alt",
  danger: "text-red-400",
  success: "text-green-500",
  warning: "text-yellow-500",
  info: "text-blue-400",
};

const ElementBgClassnames = {
  primary: "bg-primary-B100",
  secondary: "text-black bg-gray-200",
  tertiary: "bg-none",
  danger: "text-white bg-red-400",
  success: "text-white bg-green-500",
  warning: "text-white bg-yellow-500",
  info: "text-white bg-blue-400",
};

const ElementBgClassnamesWithHover = {
  primary:
    "text-white bg-primary-B100 hover:text-white border-2 border-secondary-B200 hover:bg-secondary-B200 focus:ring-4 focus:outline-none focus:ring-primary-P100 font-bold rounded-full text-center hover:ring-2 hover:ring-primary-P100",
  secondary:
    "text-secondary-B300 hover:text-white border-2 border-secondary-B300 hover:bg-secondary-B300 focus:ring-2 focus:outline-none focus:ring-primary-P100 font-bold rounded-full text-center",
  tertiary:
    "bg-none font-bold text-center text-primary-B100 underline underline-offset-4 hover:bg-secondary-B400 rounded-full focus:text-secondary-B200",
  danger: "text-white bg-red-500 hover:bg-red-400",
  success: "text-white bg-green-600 hover:bg-green-500",
  warning: "text-white bg-yellow-500 hover:bg-yellow-400",
  info: "text-white bg-blue-500 hover:bg-blue-400",
};

module.exports = {
  ElementVariant,
  ElementTextClassnames,
  ElementBgClassnames,
  ElementBgClassnamesWithHover,
};
