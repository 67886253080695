import React, { useEffect } from "react";
import { PageBanner } from "../../banners/PageBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { BodyAndImage } from "../../card/BodyAndImage";
import { Card } from "../../card/Card";
import { PrimaryButton } from "../../misc/Buttons";
import { Link } from "react-router-dom";
import { Footer } from "../../misc/Footer";
import CareersBanner from "../../../assets/careers/careers main banner.png";
import CareersImg1 from "../../../assets/careers/careers_image_1.jpeg";

import {
  CAREERS,
  CAREERS_BANNER_TITLE,
  CAREERS_BANNER_BODY,
  BOTTOM_BANNER_DETAILS,
  EXPLORE_CAREERS_AT_ENSIGN,
  CYBER_DEFENDER,
  CYBER_DEFENDER_BODY,
  CAREERS_BENEFITS_CARD,
  CAREERS_OPPORTUNITIES,
  CAREERS_BENEFITS,
  FOOTER_DETAILS,
} from "../../../constant/text";

const Careers = () => {
  useEffect(() => {
    document.title = CAREERS;
  }, []);

  return (
    <>
      <PageBanner
        name={CAREERS_BANNER_TITLE}
        body={CAREERS_BANNER_BODY}
        image={CareersBanner}
        titleColor="text-white"
        hasButton={true}
        url="https://ensigninfosecurity.wd3.myworkdayjobs.com/en-US/Ensign_Careers"
        url_string={EXPLORE_CAREERS_AT_ENSIGN}
      />
      <div className="py-4 tablet:max-w-screen-2xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <BodyAndImage
          name={CYBER_DEFENDER}
          body={CYBER_DEFENDER_BODY}
          image={CareersImg1}
          imageFloat="float-right"
        />
      </div>
      <div className="slant-line-container">
        <hr className="slant-line-blue-top" />
        <hr className="slant-line-bottom" />
      </div>
      <div className="py-4 tablet:max-w-screen-2xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="header_font_size mx-4">{CAREERS_BENEFITS[0].name}</div>
        <div className="body_font_size mx-4">{CAREERS_BENEFITS[0].body}</div>
        <div
          className="text-xs mt-2 mx-4"
          style={{ marginLeft: "auto", textAlign: "right" }}
          dangerouslySetInnerHTML={{ __html: CAREERS_BENEFITS[0].article_body }}
        />
        <Card
          numInRow={3}
          rowIcons={CAREERS_BENEFITS_CARD}
          centerChild={true}
        />
      </div>
      <div className="slant-line-container">
        <hr className="slant-line-blue-top" />
        <hr className="slant-line-bottom" />
      </div>
      <div className="pb-2 max-w-screen-2xl tablet:mx-auto mx-2 gap-4 flex-wrap">
        <div className="header_font_size pb-3 mx-4">
          {CAREERS_OPPORTUNITIES[0].name}
        </div>
        <div className="body_font_size pb-3 mx-4">
          {CAREERS_OPPORTUNITIES[0].body}
        </div>
        <div
          className="body_font_size pb-3 mx-4"
          dangerouslySetInnerHTML={{
            __html: CAREERS_OPPORTUNITIES[0].article_body,
          }}
        />
        <Link to={CAREERS_OPPORTUNITIES[0].url}>
          <div className="card_body_font_size mx-4">
            <PrimaryButton type="button" hideChildren={true}>
              {CAREERS_OPPORTUNITIES[0].url_string}
            </PrimaryButton>
          </div>
        </Link>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default Careers;
