/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useNavigate } from "react-router-dom";

export const ArticlesInsightsCard = (props) => {
  return (
    <div>
      <div className="mt-4 px-2">
        <div
          className={`tablet:grid tablet:grid-cols-2 midscreen:grid midscreen:grid-cols-3 flex-wrap gap-4`}
        >
          {props.cardDetails.map((cardDetails, index) => (
            <div className="py-4" key={index}>
              <ArticlesCardDetails
                cardDetails={cardDetails}
                korean={props.korean}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const ArticlesCardDetails = (props) => {
  const {  id } = props.cardDetails;
  const { image, banner_name, banner_description, content } = props.cardDetails;
  const backgroundColor = content !== null ? "bg-orange-200" : "bg-white";
  const contentEdit = content !== null ? content.split("_").join(" ") : "";
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/resources/${id}`);
  };
  return (
    <div
      className={`shadow-lg ${backgroundColor} relative mx-auto w-full mediumphone:w-full tablet:w-full grid grid-rows-3 smallphone:grid smallphone:grid-rows-3 mediumsmallphone:grid mediumsmallphone:grid-rows-3 mediumphone:grid mediumphone:grid-rows-4 phone:grid phone:grid-rows-5 midphone:grid midphone:grid-rows-5 largephone:w-10/12 midphone:w-11/12 mediumphone:w-full tablet:grid tablet:grid-rows-4 desktop:grid-rows-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 hover:cursor-pointer duration-200`}
      onClick={handleClick}
    >
      <div className="relative h-full tablet:h-72 widescreen:h-64 midscreen:h-72 desktop:h-96 midphone:h-full phone:h-full mediumphone:h-80 mediumsmallphone:h-72 smallphone:h-64 tablet:row-span-2 midphone:row-span-4 phone:row-span-4 mediumphone:row-span-3 smallphone:row-span-2 mediumsmallphone:row-span-2 row-span-2">
        <img
          className="resources__image"
          unoptimized={true}
          src={image}
          alt={banner_name}
        />

        <div className="absolute top-5 z-10 right-0 text-secondary-Y100 bg-primary-black">
          {contentEdit}
        </div>
      </div>

      <div
        className={`mb-8 h-20 desktop:h-64 midphone:mb-8 row-span-1 tablet:row-span-2 ${backgroundColor}`}
      >
        <h2 className="mt-2 card_header_font_size font-bold px-4 midphone:h-min">
          {banner_name}
        </h2>

        <h2 className="px-4 text-base hidden_body">{banner_description}</h2>
      </div>
    </div>
  );
};
