import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BottomBanner } from "../banners/BottomBanner";
import { Footer } from "../misc/Footer";
import { SecondaryButton } from "../misc/Buttons";
import { useNavigate } from "react-router-dom";

import {
  RESOURCESDATA,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../constant/text";

const ResourcesDetails = () => {
  useEffect(() => {
    document.title = filteredResource[0].banner_name;
  }, []);
  const { id } = useParams();
  const filteredResource = RESOURCESDATA.filter(
    (resource) => resource.id === id
  );
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/resources/");
  };
  return (
    <>
      <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <SecondaryButton
          className="phone:text-sm text-xs phone:px-4 mx-2"
          type="button"
          hideChildren={true}
          onClick={handleNavigation}
        >
          {"< "}Resources
        </SecondaryButton>
        <div className="bg-white">
          <div className="font-semibold phone:text-2xl text-xl px-8 py-4">
            {filteredResource[0].banner_name}
          </div>
          <div className="border-t-2"></div>
          <div className="largephone:grid largephone:grid-cols-1 py-2">
            <div className="largephone:grid largephone:grid-cols-2">
              <img
                unoptimized={true}
                src={filteredResource[0].image}
                width={filteredResource[0].image.width}
                height={filteredResource[0].image.height}
                alt={filteredResource[0].name}
                className="largephone:order-2"
              />
              <div
                className="break-normal px-8 phone:text-base text-sm largephone:order-1"
                dangerouslySetInnerHTML={{
                  __html: filteredResource[0].article_body,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default ResourcesDetails;
