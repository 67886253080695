/* eslint-disable jsx-a11y/alt-text */
import React from "react";

export const CardWithTopImage = (props) => {
  const numOfCol =
    props.numInRow === 1
      ? "largephone:grid-cols-1"
      : props.numInRow === 2
      ? "largephone:grid-cols-2"
      : props.numInRow === 3
      ? "largephone:grid-cols-3"
      : props.numInRow === 4
      ? "largephone:grid-cols-4"
      : null;
  return (
    <div className={`grid ${numOfCol} flex-wrap gap-8 mx-4`}>
      {props.rowIcons.map((cardDetails, index) => (
        <div className="mt-4 bg-white largephone:mt-0 rounded" key={index}>
          <CardChallenges
            cardDetails={cardDetails}
            reduceSize={props.reduceSize}
            increaseSize={props.increaseSize}
          />
        </div>
      ))}
    </div>
  );
};

const CardChallenges = (props) => {
  const { image, name, body } = props.cardDetails;
  return (
    <div>
      <div>
        <img src={image} alt={name} />

        <div>
          <h1 className="card_header_font_size">{name}</h1>
        </div>
        <div></div>
        <div>
          <div>
            <h1 className="card_body_font_size">{body}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
