import React, { useEffect } from "react";
import { HalfSizeBanner } from "../../banners/HalfSizedBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { Footer } from "../../misc/Footer";
import { BodyAndImage } from "../../card/BodyAndImage";
import { Accordion } from "../../misc/Accordion";
import InnovationsBanner from "../../../assets/innovations/innovations/ENSIGN-WEB-INNOV-CAPA-1920x300.jpg";
import InnovationsSmallBanner from "../../../assets/innovations/innovations/WEB-IMAGES-INNOV (1).jpg";
import {
  INNOVATIONS,
  INNOVATIONS_BANNER,
  INNOVATIONS_TITLE,
  INNOVATIONS_BODY,
  INNOVATIONS_ACCORDION_TITLE,
  INNOVATIONS_ACCORDION,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../../constant/text";

const Innovations = () => {
  useEffect(() => {
    document.title = INNOVATIONS;
  }, []);

  return (
    <>
      <HalfSizeBanner
        name={INNOVATIONS_BANNER}
        image={InnovationsBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="mx-4">
          <BodyAndImage
            name={INNOVATIONS_TITLE}
            body={INNOVATIONS_BODY}
            image={InnovationsSmallBanner}
            imageFloat="float-right"
          />
        </div>
        <div className="text-secondary-B200 header_font_size mx-4">
          {INNOVATIONS_ACCORDION_TITLE}
        </div>
        <div className="mx-4">
          {INNOVATIONS_ACCORDION.map((accordion) => (
            <Accordion key={accordion.id} details={accordion} />
          ))}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default Innovations;
