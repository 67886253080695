import React, { useEffect } from "react";
import { HalfSizeBanner } from "../../banners/HalfSizedBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { Footer } from "../../misc/Footer";
import { LeadershipTab } from "../../misc/tabs/LeadershipTab";

import LeadershipBanner from "../../../assets/leadership/1920x300-LEADERS.png";
import {
  OUR_LEADERSHIP,
  ENSIGN_LEADERSHIP,
  MANAGEMENTDATA,
  DIRECTORSDATA,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../../constant/text";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

const Innovations = () => {
  useEffect(() => {
    document.title = OUR_LEADERSHIP;
  }, []);

  return (
    <>
      <HalfSizeBanner
        name={ENSIGN_LEADERSHIP}
        image={LeadershipBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-xl smallphone:mx-4 mx-0 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Tabs isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab _selected={{ color: "white", bg: "blue.500" }}>
              Our Management Team
            </Tab>
            <Tab _selected={{ color: "white", bg: "blue.500" }}>
              Our Board of Directors
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <LeadershipTab leaderList={MANAGEMENTDATA} />
            </TabPanel>
            <TabPanel>
              <LeadershipTab leaderList={DIRECTORSDATA} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default Innovations;
