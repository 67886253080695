import React, { useEffect } from "react";
import { HalfSizeBanner } from "../../banners/HalfSizedBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { Footer } from "../../misc/Footer";
import { BodyAndImage } from "../../card/BodyAndImage";
import PatentsBanner from "../../../assets/innovations/patents/ENSIGN-WEB-PATENTSjpg.jpg";
import PatentsSmallBanner from "../../../assets/innovations/patents/SOC (1).png";
import {
  PATENTS,
  PATENTS_BANNER,
  PATENTS_TITLE,
  PATENTS_BODY,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../../constant/text";

const Patents = () => {
  useEffect(() => {
    document.title = PATENTS;
  }, []);

  return (
    <>
      <HalfSizeBanner
        name={PATENTS_BANNER}
        image={PatentsBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="mx-4">
          <BodyAndImage
            name={PATENTS_TITLE}
            body={PATENTS_BODY}
            image={PatentsSmallBanner}
            imageFloat="float-right"
          />
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default Patents;
