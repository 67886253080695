/* eslint-disable jsx-a11y/alt-text */
import React from "react";
export const HalfSizeBanner = (props) => {
  return (
    <div>
      <div className="halfbanner__container">
        <img className="halfbanner__image" src={props.image} alt={props.name} />
        <div
          className={`py-4 tablet:max-w-screen-xl mx-4 my-auto tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap halfbanner__text`}
        >
          <div className="tablet:grid tablet:grid-cols-3 grid  grid-flow-col ">
            <div className="tablet:col-span-3 ">
              <div
                className={` ${props.titleColor} tablet:text-4xl desktop:text-5xl phone:text-2xl text-base text-wrap-balance mx-4 font-bold`}
              >
                {props.name}
              </div>
              <h1 className="text-white py-4 text-xl tablet:text-4xl midphone:text-base mx-4">
                {props.body}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
