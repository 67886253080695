/* eslint-disable jsx-a11y/alt-text */

export const CardWithImage = (props) => {
  const numOfCol =
    props.numInRow === 1
      ? "tablet:grid-cols-1"
      : props.numInRow === 2
      ? "tablet:grid-cols-2"
      : props.numInRow === 3
      ? "tablet:grid-cols-3"
      : props.numInRow === 4
      ? "tablet:grid-cols-4"
      : null;
  return (
    <div className="mt-2">
      <div className={`grid ${numOfCol} flex-wrap gap-4`}>
        {props.rowIcons.map((cardDetails, index) => (
          <div
            className={`${
              props.notToHaveBorder ? "" : "border-2"
            } midphone:mt-0 rounded`}
            key={index}
          >
            <CardChallenges
              cardDetails={cardDetails}
              backgroundColor={props.backgroundColor}
              centerChild={props.centerChild}
              reduceSize={props.reduceSize}
              increaseSize={props.increaseSize}
              articleBody={props.articleBody}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const CardChallenges = (props) => {
  const { name, body, image, article_body } = props.cardDetails;
  return (
    <div
      className={`pt-2 px-2 flex flex-col h-full ${props.backgroundColor} rounded-2xl`}
    >
      <div
        className={`${props.centerChild ? "flex flex-col items-center" : ""}`}
      >
        <img src={image} alt={name} />

        <div>
          <h1
            className={`card_body_font_size ${
              props.centerChild ? "text-center" : "text-left"
            } font-bold mb-2 ${props.titleColor}`}
          >
            {name}
          </h1>
        </div>
        <div>
          <div>
            {props.articleBody ? (
              <div
                className={`phone:text-base text-sm ${
                  props.centerChild ? "text-center" : "text-left"
                }`}
                dangerouslySetInnerHTML={{ __html: article_body }}
              />
            ) : (
              <h1
                className={`phone:text-base text-sm ${
                  props.centerChild ? "text-center" : "text-left"
                }`}
              >
                {body}
              </h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
