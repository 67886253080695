import useEmblaCarousel from "embla-carousel-react";
import { useState, useEffect, useCallback } from "react";

const CardCarousel = (props) => {
  const [emblaRef, embla] = useEmblaCarousel({
    align: "start",
    loop: false,
    skipSnaps: true,
    inViewThreshold: 0.1,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );
  const scrollPrev = useCallback(() => embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla.scrollNext(), [embla]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div className="embla bg-secondary-G200/20 mr-0 px-5">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container__mobile">
          {props.rowIcons.map((post, index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__inner">
                <div
                  className={`${props.backgroundColor} relative largephone:h-48 phone:h-52 smallphone:h-56 smallmiddlephone:h-56 h-80 w-10/12 rounded-xl`}
                >
                  <div className="mb-6 px-2">
                    <h2 className="card_header_font_size font-bold row-span-2 mt-2 mb-4">
                      {post.name}
                    </h2>

                    <h1 className="mt-2 mb-5 card_body_font_size">
                      {post.body}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <button
        className="embla__button embla__button__card--prev ml-2"
        type="button"
        onClick={scrollPrev}
      >
        <svg
          className="embla__button__svg"
          viewBox="137.718 -1.001 366.563 644"
        >
          <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
        </svg>
      </button>

      <button
        className="embla__button embla__button__card--next mr-3"
        type="button"
        onClick={scrollNext}
      >
        <svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
          <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
        </svg>
      </button>

      <div className="flex items-center justify-center mt-5 space-x-2">
        {scrollSnaps.map((_, idx) => (
          <button
            className={`w-2 h-2 rounded-full ${
              idx === selectedIndex ? "bg-yellow-500" : "bg-gray-300"
            }`}
            key={idx}
            onClick={() => scrollTo(idx)}
          />
        ))}
      </div>
    </div>
  );
};

export default CardCarousel;
