/* eslint-disable jsx-a11y/alt-text */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { PrimaryButton, SecondaryButton } from "../Buttons";

export const LeadershipTab = (props) => {
  return (
    <div className="mt-10">
      <div className="tablet:grid tablet:grid-cols-2 w-full flex flex-wrap gap-4">
        {props.leaderList.map((cardDetails, index) => (
          <div key={index}>
            <CardChallenges
              key={index}
              cardDetails={cardDetails}
              korean={props.korean}
              chinese={props.chinese}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const CardChallenges = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { leadership_image, leadership_name, title, description } =
    props.cardDetails;
  return (
    <div className="smallphone:px-4">
      <LeadershipModal
        isOpen={isOpen}
        onClose={onClose}
        leadership_name={leadership_name}
        title={title}
        description={description}
        image={leadership_image}
      />

      <div className="relative flex flex-col min-w-0 rounded break-words border border-1 border-gray-300 mb-5 border-0">
        <div className="smallphone:flex smallphone:flex-wrap smallphone:no-gutters text-black bg-secondary-G200/40">
          <div className="smallphone:w-2/5">
            <img
              unoptimized={true}
              src={leadership_image}
              alt={leadership_name}
            />
          </div>
          <div className="smallphone:w-3/5">
            <div className="relative flex-1 px-2">
              <div className="desktop:text-2xl text-base font-medium my-2 mt-4">
                {leadership_name}
              </div>
              <div className="desktop:text-lg text-xs phone:w-60 smallphone:w-40 w-fit phone:h-24 smallphone:h-16 h-12">
                {title}
              </div>
              <div>
                <SecondaryButton
                  onClick={onOpen}
                  type="button"
                  hideChildren={true}
                  className="desktop:text-lg text-xs"
                >
                  VIEW PROFILE
                </SecondaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LeadershipModal = (props) => {
  const { isOpen, onClose, title, leadership_name, description, image } = props;
  return (
    <div>
      <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxH="150rem" maxW="50rem">
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div className="flex flex-wrap  flex-col midphone:flex-row">
              <div className="midphone:w-1/3 pr-4 pl-4 text-center">
                <img unoptimized={true} src={image} alt={title} />
                <div className="mt-3 phone:text-2xl text-xl font-medium">
                  {leadership_name}
                </div>
                <div className="phone:text-lg text-base">{title}</div>
              </div>
              <div className="relative flex-grow max-w-full flex-1 px-4 py-4">
                <div
                  className="break-normal phone:text-base text-sm"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </div>
          </ModalBody>
          <div className="flex justify-center my-8">
            <PrimaryButton onClick={onClose} type="button" hideChildren={true}>
              Close
            </PrimaryButton>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};
