import React, { useEffect } from "react";
import { HalfSizeBanner } from "../banners/HalfSizedBanner";
import { BottomBanner } from "../banners/BottomBanner";
import { Footer } from "../misc/Footer";
import { Card } from "../card/Card";
import { Accordion } from "../misc/Accordion";
import CapabilitiesBanner from "../../assets/capabilities/ENSIGN-WEB-CAPA-1920x300.jpg";
import {
  CAPABILITIES,
  CAPABILITIES_BANNER,
  CAPABILITIES_TITLE,
  CAPABILITIES_BODY,
  CAPABILITIES_CARD_TITLE,
  CAPABILITIES_CARD,
  CAPABILITIES_ACCORDION_TITLE,
  CAPABILITIES_ACCORDION,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../constant/text";

const Capabilities = () => {
  useEffect(() => {
    document.title = CAPABILITIES;
  }, []);

  return (
    <>
      <HalfSizeBanner
        name={CAPABILITIES_BANNER}
        image={CapabilitiesBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="flex header_font_size tablet:mx-auto mx-4">
          {CAPABILITIES_TITLE}
        </div>
        <div className="flex body_font_size tablet:mx-auto mx-4">
          {CAPABILITIES_BODY}
        </div>
        <div className="text-secondary-B200 header_font_size">
          {CAPABILITIES_CARD_TITLE}
        </div>
        <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto mx-4 tablet:flex tablet:flex-auto gap-4 flex-wrap">
          <Card numInRow={4} rowIcons={CAPABILITIES_CARD} centerChild={true} />
        </div>
        <div className="text-secondary-B200 header_font_size mx-4">
          {CAPABILITIES_ACCORDION_TITLE}
        </div>
        <div className="mx-4">
          {CAPABILITIES_ACCORDION.map((accordion) => (
            <Accordion key={accordion.id} details={accordion} />
          ))}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default Capabilities;
