import React, { useState, useRef, useEffect } from "react";

export const Accordion = (props) => {
  const {
    name,
    sub_header,
    article_body,
    image,
  } = props.details;
  const [isExpanded, setExpanded] = useState(false);
  const [dropDownheight, setDropDownheight] = useState(0);
  const dropDownRef = useRef(null); // Removed the unnecessary type annotation

  const toggleAccordion = () => {
    setExpanded(!isExpanded);
  };

  const calculateHeight = () => {
    if (dropDownRef.current) {
      setDropDownheight(isExpanded ? dropDownRef.current.scrollHeight : 0);
    }
  };

  useEffect(() => {
    calculateHeight(); // Initial calculation

    window.addEventListener("resize", calculateHeight); // Add resize event listener

    return () => {
      window.removeEventListener("resize", calculateHeight); // Clean up event listener
    };
  }, [isExpanded]);

  return (
    <div className="border border-gray-300 rounded-lg p-2 mb-4 ">
      <div
        className="flex items-center justify-between cursor-pointer tablet:grid tablet:grid-cols-12 grid grid-cols-8 gap-2"
        onClick={toggleAccordion}
      >
        <div className="flex items-center tablet:col-span-1 col-span-1 ml-2 hide_accordion">
          {!props.noImage && (
            <img
              unoptimized={true}
              src={image}
              alt={name}
              width={image.width}
              height={image.height}
              priority
            />
          )}
        </div>

        <div className="col-start-1 col-end-7 smallphone:col-start-2 smallphone:col-end-7 tablet:col-start-2 tablet:col-end-10 flex flex-col">
          <div className="card_header_font_size text-secondary-B200">
            {name}
          </div>
          <div className="body_font_size">{sub_header}</div>
        </div>
        <div className="tablet:col-start-12 smallphone:col-start-8 col-start-7">
          {isExpanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-12 h-12 text-primary-B100"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M5 12H19V10H5V12Z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-12 h-12 text-primary-B100"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M19 11H13V5H11V11H5V13H11V19H13V13H19V11Z" />
            </svg>
          )}
        </div>
      </div>
      <div
        className="overflow-hidden transition-height duration-300"
        style={{ height: `${dropDownheight}px` }}
      >
        <div
          ref={dropDownRef}
          className="tablet:grid tablet:grid-cols-12 grid grid-cols-8"
        >
          <div className="col-start-1 col-end-12 smallphone:col-start-2 smallphone:col-end-12 mt-2 card_body_font_size">
            <div
              className="break-normal card_body_font_size"
              dangerouslySetInnerHTML={{
                __html: article_body,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
