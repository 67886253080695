import React, { useEffect } from "react";
import { HalfSizeBanner } from "../../banners/HalfSizedBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { Footer } from "../../misc/Footer";
import { BodyAndImage } from "../../card/BodyAndImage";
import { Accordion } from "../../misc/Accordion";

import ExecutiveAdvisoryBanner from "../../../assets/service listing/executive advisory/1920x300-ADVISORY.png";
import ExecutiveSmallBanner from "../../../assets/service listing/executive advisory/WEB-IMAGES-ADVISORY (1).jpg";
import {
  EXECUTIVEADVISORY,
  EXECUTIVEADVISORY_TITLE,
  EXECUTIVEADVISORY_BODY,
  WHAT_DOES_ENSIGN_OFFER,
  EXECUTIVEADVISORY_ACCORDION,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../../constant/text";

const ExecutiveAdvisory = () => {
  useEffect(() => {
    document.title = EXECUTIVEADVISORY;
  }, []);

  return (
    <>
      <HalfSizeBanner
        name={EXECUTIVEADVISORY}
        image={ExecutiveAdvisoryBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="mx-4">
          <BodyAndImage
            name={EXECUTIVEADVISORY_TITLE}
            body={EXECUTIVEADVISORY_BODY}
            image={ExecutiveSmallBanner}
            imageFloat="float-right"
          />
        </div>
        <div className="text-secondary-B200 header_font_size mx-4">
          {WHAT_DOES_ENSIGN_OFFER}
        </div>
        <div className="mx-4">
          {EXECUTIVEADVISORY_ACCORDION.map((accordion) => (
            <Accordion key={accordion.id} details={accordion} />
          ))}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default ExecutiveAdvisory;
