import classNames from "classnames";
import {
  ElementBgClassnames,
  ElementBgClassnamesWithHover,
} from "../interfaces/element";
import { SmallLoader } from "./Loaders";

export const Button = (props) => {
  const { children, className, ...restProps } = props;
  return (
    <button
      className={`transition px-8 pt-2 pb-2 ${className || ""}`}
      {...restProps}
    >
      {children}
    </button>
  );
};

export const PrimaryButton = (props) => {
  const {
    hideChildren = false,
    variant,
    isLoading,
    children,
    className,
    disabled,
    ...rest
  } = props;
  const elementClassNames = disabled
    ? ElementBgClassnames[variant || "primary"]
    : ElementBgClassnamesWithHover[variant || "primary"];

  return (
    <Button
      className={classNames(
        "baseBtn",
        elementClassNames,
        className,
        "disabled:opacity-50"
      )}
      {...rest}
      disabled={!!isLoading || disabled}
    >
      {isLoading ? (
        <div className="whitespace-nowrap">
          {!hideChildren && children}
          &nbsp;
          <SmallLoader />
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

export const SecondaryButton = (props) => {
  const {
    hideChildren = false,
    variant,
    isLoading,
    children,
    className,
    disabled,
    ...rest
  } = props;
  const elementClassNames = disabled
    ? ElementBgClassnames[variant || "secondary"]
    : ElementBgClassnamesWithHover[variant || "secondary"];

  return (
    <Button
      className={classNames(
        "baseBtn",
        elementClassNames,
        className,
        "disabled:opacity-50"
      )}
      {...rest}
      disabled={!!isLoading || disabled}
    >
      {isLoading ? (
        <div className="whitespace-nowrap">
          {!hideChildren && children}
          &nbsp;
          <SmallLoader />
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

export const TertiaryButton = (props) => {
  const {
    hideChildren = false,
    variant,
    isLoading,
    children,
    className,
    disabled,
    ...rest
  } = props;
  const elementClassNames = disabled
    ? ElementBgClassnames[variant || "tertiary"]
    : ElementBgClassnamesWithHover[variant || "tertiary"];

  return (
    <Button
      className={classNames(
        "baseBtn",
        elementClassNames,
        className,
        "disabled:opacity-50"
      )}
      {...rest}
      disabled={!!isLoading || disabled}
    >
      {isLoading ? (
        <div className="whitespace-nowrap">
          {!hideChildren && children}
          &nbsp;
          <SmallLoader />
        </div>
      ) : (
        children
      )}
    </Button>
  );
};
