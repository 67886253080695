import React, { useEffect, useRef, useState } from "react";
import logoPath from "../../assets/ensign-logo.png";
import { SecondaryButton } from "../misc/Buttons";
import { GenerateFontAwesomeIcon } from "../misc/Helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

interface NavigationLinkPropsSample {
  href: string;
  label: string;
  megaMenuItems?: MegaMenuItemSample[];
}

interface MegaMenuItemSample {
  title: string;
  body: string;
  items: NavigationLinkPropsSample[];
}

interface MegaMenuItem {
  name: string;
  position_of_sub_header: string;
  position_of_sub_header_content: string;
  description: string;
  symbol: any;
  href: string;
  body: string;
}

interface NavigationBarProps {
  navBarData: any;
  navBarCarouselData: any;
}
interface NavigationLinkProps {
  href: string;
  label: string;
  carousel: any;
  navBarCarouselData: any;
  megaMenuItems?: MegaMenuItem[];
}
interface MobileNavigationLinkProps {
  navBarCarouselData: any;
  firstNavBarHeader?: MegaMenuItem[];
  secondNavBarHeader?: MegaMenuItem[];
  thirdNavBarHeader?: MegaMenuItem[];
  fourthNavBarHeader?: MegaMenuItem[];
  fifthNavBarHeader?: MegaMenuItem[];
  sixthNavBarHeader?: MegaMenuItem[];
  seventhNavBarHeader?: MegaMenuItem[];
  navbarHeader: any;
  handleToggleClick: any;
}

// const NavigationLinkSample: React.FC<NavigationLinkPropsSample> = ({
//   href,
//   label,
//   megaMenuItems
// }) => {
//   const [showMegaMenu, setShowMegaMenu] = useState(false);
//   const timeoutRef = useRef<number>();

//   useEffect(() => {
//     return () => {
//       clearTimeout(timeoutRef.current);
//     };
//   }, []);

//   const handleMouseEnter = () => {
//     setShowMegaMenu(true);
//   };

//   const handleMouseLeave = () => {
//     timeoutRef.current = window.setTimeout(() => {
//       setShowMegaMenu(false);
//     }, 100);
//   };

//   const handleMouseOver = () => {
//     clearTimeout(timeoutRef.current);
//   };

//   return (
//     <div
//       onMouseEnter={handleMouseEnter}
//       onMouseLeave={handleMouseLeave}
//       onMouseOver={handleMouseOver}
//     >
//       <div className="relative">
//         <a href={href}>
//           <span className="cursor-pointer text-gray-600 hover:text-blue-800 px-4 py-2 rounded-md text-md font-medium">
//             {label}
//           </span>
//         </a>
//       </div>
//       {megaMenuItems && showMegaMenu && (
//         <div
//           className={`absolute mt-6 left-0 w-full bg-white shadow-lg z-10 ${
//             showMegaMenu ? 'visible' : 'hidden'
//           }`}
//         >
//           <MegaMenuSample items={megaMenuItems} />
//         </div>
//       )}
//     </div>
//   );
// };

const NavigationLink: React.FC<NavigationLinkProps> = ({
  href,
  label,
  carousel,
  navBarCarouselData,
  megaMenuItems,
}) => {
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleMouseEnter = () => {
    setShowMegaMenu(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setShowMegaMenu(false);
    }, 100);
  };

  const handleMouseOver = () => {
    clearTimeout(timeoutRef.current);
  };

  const closeNavBar = () => {
    setShowMegaMenu(false);
  };
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseOver}
    >
      <div className="relative">
        {href ? (
          <a href={href}>
            <span className="cursor-pointer text-gray-600 hover:text-blue-800 px-4 py-2 rounded-md text-md font-medium">
              {label}
            </span>
          </a>
        ) : (
          <div className="cursor-pointer text-gray-600 hover:text-blue-800 px-4 py-2 rounded-md text-md font-medium">
            {label}
          </div>
        )}
      </div>
      {megaMenuItems && showMegaMenu && (
        <div
          className={`absolute ${
            carousel ? "mt-2" : "mt-5"
          } left-0 w-full bg-white shadow-lg z-10 border-t-2 ${
            showMegaMenu ? "visible" : "hidden"
          }`}
        >
          <MegaMenu
            items={megaMenuItems}
            carousel={carousel}
            navBarCarouselData={navBarCarouselData}
            closeNavBar={closeNavBar}
          />
        </div>
      )}
    </div>
  );
};

interface MegaMenuPropsSample {
  items: MegaMenuItemSample[];
}

// const MegaMenuSample: React.FC<MegaMenuPropsSample> = ({ items }) => {
//   return (
//     <div className="grid grid-cols-3 gap-x-4 p-4">
//       {items.map((item, index) => (
//         <div key={index}>
//           <div className="flex flex-row">
//             {/* <FontAwesomeIcon icon={faStore} /> */}
//             <h4 className="pl-2 text-lg font-medium mb-2">{item.title}</h4>
//           </div>

//           <p className="text-xs font-medium mb-2">{item.body}</p>
//           <ul className="mb-4">
//             {item.items.map((navLink, index) => (
//               <li key={index}>
//                 <a href={navLink.href}>
//                   <span className="text-gray-700 hover:text-blue-600 cursor-pointer">
//                     {navLink.label}
//                   </span>
//                 </a>
//               </li>
//             ))}
//           </ul>
//         </div>
//       ))}
//     </div>
//   );
// };

interface MegaMenuProps {
  items: MegaMenuItem[];
  carousel: any;
  navBarCarouselData: any;
  closeNavBar: any;
}

const MegaMenu: React.FC<MegaMenuProps> = ({
  items,
  carousel,
  navBarCarouselData,
  closeNavBar,
}) => {
  const subHeaderNavBar = items.filter(function (type: any) {
    return type.type === "sub_header";
  });
  subHeaderNavBar.sort(function (a: any, b: any) {
    return (
      parseFloat(a.position_of_sub_header) -
      parseFloat(b.position_of_sub_header)
    );
  });
  const subHeaderContentNavBar = items.filter(function (type: any) {
    return type.type === "sub_header_content";
  });
  subHeaderContentNavBar.sort(function (a: any, b: any) {
    return (
      parseFloat(a.position_of_sub_header_content) -
      parseFloat(b.position_of_sub_header_content)
    );
  });
  return (
    <div className="flex">
      <div className="flex grow justify-center flex flex-wrap">
        {subHeaderNavBar.map((item, index) => (
          <div
            className={`${
              subHeaderNavBar.length === 1
                ? "widerscreen:basis-3/12 widescreen:basis-4/12 midscreen:basis-5/12 desktop:basis-7/12 tablet:basis-8/12 p-4 justify-center"
                : subHeaderNavBar.length === 2
                ? "basis-1/3 p-4 justify-center"
                : "basis-1/4 p-4 justify-center"
            } `}
            key={index}
          >
            <div className="flex flex-row">
              {item.href ? (
                <a href={item.href}>
                  <span
                    onClick={() => {
                      closeNavBar();
                    }}
                    className="text-lg font-medium text-primary-B100 hover:text-secondary-B300 cursor-pointer"
                  >
                    {item.name}
                  </span>
                </a>
              ) : (
                <h4 className="text-lg font-medium mb-2">{item.name}</h4>
              )}
            </div>
            <p className="mb-2 text-gray-600">{item.description}</p>
            <ul className="mb-4" style={{ columns: "2", columnGap: "1rem" }}>
              {subHeaderContentNavBar.map((content, index) =>
                content.position_of_sub_header ===
                  item.position_of_sub_header && content.name ? (
                  <li key={index}>
                    {content.href && (
                      <a href={content.href}>
                        <span
                          onClick={() => {
                            closeNavBar();
                          }}
                          className="text-primary-B100 hover:text-secondary-B300 cursor-pointer"
                        >
                          {content.name}
                        </span>
                      </a>
                    )}
                    <p className="text-gray-600 mb-2">{content.description}</p>
                  </li>
                ) : content.position_of_sub_header ===
                    item.position_of_sub_header && content.body ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: content.body,
                    }}
                  />
                ) : (
                  ""
                )
              )}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
interface MobileMegaMenuProps {
  items: any;
  carousel: any;
  navBarCarouselData: any;
  closeMobileTab: any;
}
type Tab = {
  id: string;
  label: string;
  content: any;
  carousel: any;
  headerDetails: any;
  symbol: any;
};

const MobileNavigationLink: React.FC<MobileNavigationLinkProps> = ({
  firstNavBarHeader,
  secondNavBarHeader,
  thirdNavBarHeader,
  fourthNavBarHeader,
  fifthNavBarHeader,
  sixthNavBarHeader,
  seventhNavBarHeader,
  navbarHeader,
  navBarCarouselData,
  handleToggleClick,
}) => {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [showTabs, setShowTabs] = useState<boolean>(true);

  const handleClick = (tabId: string) => {
    setActiveTab(tabId);
    setShowTabs(false);
  };

  const handleBackClick = () => {
    setActiveTab(null);
    setShowTabs(true);
  };

  const closeMobileTab = () => {
    handleToggleClick(false);
    setActiveTab(null);
    setShowTabs(true);
  };
  const homePageDaata = { href: "/", name: "Home", have_sub_header: false };
  const tabs: Tab[] = [
    {
      id: "home",
      label: "Home",
      content: [],
      carousel: false,
      headerDetails: homePageDaata,
      symbol: "faHome",
    },
    {
      id: navbarHeader[0].name,
      label: navbarHeader[0].name,
      content: firstNavBarHeader,
      carousel: navbarHeader[0].carousel,
      headerDetails: navbarHeader[0],
      symbol: "faLightbulb",
    },
    {
      id: navbarHeader[1].name,
      label: navbarHeader[1].name,
      content: secondNavBarHeader,
      carousel: navbarHeader[1].carousel,
      headerDetails: navbarHeader[1],
      symbol: "faGear",
    },
    {
      id: navbarHeader[2].name,
      label: navbarHeader[2].name,
      content: thirdNavBarHeader,
      carousel: navbarHeader[2].carousel,
      headerDetails: navbarHeader[2],
      symbol: "faBars",
    },
    {
      id: navbarHeader[3].name,
      label: navbarHeader[3].name,
      content: fourthNavBarHeader,
      carousel: navbarHeader[3].carousel,
      headerDetails: navbarHeader[3],
      symbol: "faSuitcase",
    },
    {
      id: navbarHeader[4].name,
      label: navbarHeader[4].name,
      content: fifthNavBarHeader,
      carousel: navbarHeader[4].carousel,
      headerDetails: navbarHeader[4],
      symbol: "faFolderOpen",
    },
    {
      id: navbarHeader[5].name,
      label: navbarHeader[5].name,
      content: sixthNavBarHeader,
      carousel: navbarHeader[5].carousel,
      headerDetails: navbarHeader[5],
      symbol: "faCircleInfo",
    },
    {
      id: navbarHeader[6].name,
      label: navbarHeader[6].name,
      content: sixthNavBarHeader,
      carousel: navbarHeader[6].carousel,
      headerDetails: navbarHeader[6],
      symbol: "faPhone",
    },
  ];
  return (
    <header>
      <div>
        {showTabs && (
          <ul className="phone:border-l-4 border-l-2 border-secondary-Y100">
            {tabs.map((tab) => (
              <li
                className="phone:border-b-4 border-b-2 border-secondary-R100 py-2"
                key={tab.id}
              >
                {tab.headerDetails.have_sub_header ? (
                  <div
                    className="flex flex-row smallphone:grid smallphone:grid-cols-12 smallerphone:grid smallerphone:grid-cols-8 grid grid-cols-6"
                    onClick={() => handleClick(tab.id)}
                  >
                    <div className="hide_mobilenavbar_symbol smallphone:px-2 pl-1 smallphone:col-start-4 smallphone:justify-self-center smallerphone:justify-self-end justify-self-start">
                      <GenerateFontAwesomeIcon iconName={tab.symbol} />
                    </div>
                    <div className="smallphone:col-span-6 smallerphone:col-span-6 verysmallphone:col-span-4 col-span-5 pl-1">
                      <button className="text-primary-B100 hover:text-secondary-B300 font-bold largephone:text-3xl smallerphone:text-xl text-sm">
                        {tab.label}
                      </button>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        className="font_awesome_size col-end-auto	"
                        icon={faChevronRight}
                      />
                    </div>
                  </div>
                ) : (
                  <a
                    className="flex flex-row smallphone:grid smallphone:grid-cols-12 smallerphone:grid smallerphone:grid-cols-8 grid grid-cols-6"
                    href={tab.headerDetails.href}
                  >
                    <div className=" hide_mobilenavbar_symbol smallphone:px-2 pl-1 smallphone:col-start-4 smallphone:justify-self-center smallerphone:justify-self-end justify-self-start">
                      <GenerateFontAwesomeIcon iconName={tab.symbol} />
                    </div>
                    <div className="smallphone:col-span-6 smallerphone:col-span-7 col-span-5 pl-1">
                      <div className="text-primary-B100 hover:text-secondary-B300 font-bold largephone:text-3xl smallerphone:text-xl text-sm">
                        <span
                          onClick={() => {
                            closeMobileTab();
                          }}
                        >
                          {tab.label}
                        </span>
                      </div>
                    </div>
                  </a>
                )}
              </li>
            ))}
          </ul>
        )}
        {activeTab && (
          <div>
            <button className="text-xl" onClick={handleBackClick}>
              {"< "}Back
            </button>
            <div>
              {tabs.find((tab) => tab.id === activeTab) &&
                tabs.find((tab) => tab.id === activeTab) && (
                  <MobileMegaMenu
                    items={tabs.find((tab) => tab.id === activeTab)?.content}
                    carousel={
                      tabs.find((tab) => tab.id === activeTab)?.carousel
                    }
                    navBarCarouselData={navBarCarouselData}
                    closeMobileTab={closeMobileTab}
                  />
                )}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

const MobileMegaMenu: React.FC<MobileMegaMenuProps> = ({
  items,
  carousel,
  navBarCarouselData,
  closeMobileTab,
}) => {
  const subHeaderNavBar = items.filter(function (type: any) {
    return type.type === "sub_header";
  });
  subHeaderNavBar.sort(function (a: any, b: any) {
    return (
      parseFloat(a.position_of_sub_header) -
      parseFloat(b.position_of_sub_header)
    );
  });
  const subHeaderContentNavBar = items.filter(function (type: any) {
    return type.type === "sub_header_content";
  });
  subHeaderContentNavBar.sort(function (a: any, b: any) {
    return (
      parseFloat(a.position_of_sub_header) -
      parseFloat(b.position_of_sub_header)
    );
  });
  return (
    <div className="sub-menu">
      {subHeaderNavBar.map((item: any, index: any) => (
        <div key={index}>
          <div>
            {item.href ? (
              <a href={item.href}>
                <div className="mb-2">
                  <span
                    onClick={() => {
                      closeMobileTab();
                    }}
                    className="pl-2 text-2xl smallerphone:text-lg text-sm font-bold text-primary-B100 hover:text-secondary-B300 cursor-pointer"
                  >
                    {item.name}
                  </span>
                </div>
                <p className="text-xl smallerphone:text-base text-xs font-medium mb-2">
                  {item.description}
                </p>
                <div className="phone:border-b-4 border-b-2 border-secondary-R100"></div>
              </a>
            ) : (
              ""
            )}
          </div>
          <ul className="mb-4">
            {subHeaderContentNavBar.map((content: any, index: any) =>
              content.position_of_sub_header === item.position_of_sub_header &&
              content.name ? (
                <li key={index} className="py-2">
                  {content.href && (
                    <a href={content.href}>
                      <span
                        onClick={() => {
                          closeMobileTab();
                        }}
                        className="text-2xl smallerphone:text-lg text-sm font-bold text-primary-B100 hover:text-secondary-B300 cursor-pointer"
                      >
                        {content.name}
                      </span>
                    </a>
                  )}

                  <p className="text-xl smallerphone:text-base text-xs font-medium mb-2">
                    {content.description}
                  </p>
                  <div className="phone:border-b-4 border-b-2 border-secondary-R100"></div>
                </li>
              ) : content.position_of_sub_header ===
                  item.position_of_sub_header && content.body ? (
                <>
                  <div
                    className="mb-2 text-lg smallerphone:text-base text-xs font-medium"
                    dangerouslySetInnerHTML={{
                      __html: content.body,
                    }}
                  />
                  <div className="phone:border-b-4 border-b-2 border-secondary-R100"></div>
                </>
              ) : (
                ""
              )
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

const NavigationBar: React.FC<NavigationBarProps> = ({
  navBarData,
  navBarCarouselData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const headerData = navBarData.filter(function (type: any) {
    return type.type === "header";
  });
  headerData.sort(function (a: any, b: any) {
    return parseFloat(a.position_of_header) - parseFloat(b.position_of_header);
  });
  const firstNavBarHeader = navBarData.filter(function (header_type: any) {
    return header_type.header_type === "1st";
  });
  const secondNavBarHeader = navBarData.filter(function (header_type: any) {
    return header_type.header_type === "2nd";
  });
  const thirdNavBarHeader = navBarData.filter(function (header_type: any) {
    return header_type.header_type === "3rd";
  });
  const fourthNavBarHeader = navBarData.filter(function (header_type: any) {
    return header_type.header_type === "4th";
  });
  const fifthNavBarHeader = navBarData.filter(function (header_type: any) {
    return header_type.header_type === "5th";
  });
  const sixthNavBarHeader = navBarData.filter(function (header_type: any) {
    return header_type.header_type === "6th";
  });
  const seventhNavBarHeader = navBarData.filter(function (header_type: any) {
    return header_type.header_type === "7th";
  });
  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <nav className="bg-white-800">
      <div className="border-b-2 border-slate-100">
        <div className="flex grow items-center max-w-screen-xl mx-auto h-20">
          <div className="tablet:basis-1/6 mx-2">
            <a href="/">
              <img src={logoPath} alt="logo" width={140} height={100} />
            </a>
          </div>
          <div className="mx-auto hidden tablet:block text-black">
            <div className="flex flex-1 items-center space-x-4">
              <NavigationLink
                href={`${
                  headerData[0].have_sub_header ? "" : headerData[0].href
                }`}
                label={headerData[0].name}
                navBarCarouselData=""
                carousel={headerData[0].carousel}
                megaMenuItems={firstNavBarHeader}
              />
              <NavigationLink
                href={`${
                  headerData[1].have_sub_header ? "" : headerData[1].href
                }`}
                label={headerData[1].name}
                navBarCarouselData=""
                carousel={headerData[1].carousel}
                megaMenuItems={undefined}
              />
              <NavigationLink
                href={`${
                  headerData[2].have_sub_header ? "" : headerData[2].href
                }`}
                label={headerData[2].name}
                navBarCarouselData=""
                carousel={headerData[2].carousel}
                megaMenuItems={thirdNavBarHeader}
              />
              <NavigationLink
                href={`${
                  headerData[3].have_sub_header ? "" : headerData[3].href
                }`}
                label={headerData[3].name}
                navBarCarouselData=""
                carousel={headerData[3].carousel}
                megaMenuItems={fourthNavBarHeader}
              />
              <NavigationLink
                href={`${
                  headerData[4].have_sub_header ? "" : headerData[4].href
                }`}
                label={headerData[4].name}
                navBarCarouselData=""
                carousel={headerData[4].carousel}
                megaMenuItems={undefined}
              />
              <NavigationLink
                href={`${
                  headerData[5].have_sub_header ? "" : headerData[5].href
                }`}
                label={headerData[5].name}
                navBarCarouselData={navBarCarouselData}
                carousel={headerData[5].carousel}
                megaMenuItems={sixthNavBarHeader}
              />
            </div>
          </div>
          <div className="hidden tablet:block text-black">
            <a href={headerData[6].href}>
              <SecondaryButton
                type="button"
                className="tablet:mx-2 text-md font-medium"
              >
                {headerData[6].name}
              </SecondaryButton>
            </a>
          </div>
          <div className="ml-auto tablet:hidden">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-black-400 hover:text-black hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
              aria-label="Main menu"
              onClick={handleToggleClick}
            >
              <svg
                className={`${isOpen ? "hidden" : "block"} h-6 w-6`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className={`${isOpen ? "block" : "hidden"} h-6 w-6`}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={`${isOpen ? "block" : "hidden"} tablet:hidden`}>
        <div className="px-2 pt-2 pb-3 sm:px-3">
          <MobileNavigationLink
            navBarCarouselData={navBarCarouselData}
            navbarHeader={headerData}
            firstNavBarHeader={firstNavBarHeader}
            secondNavBarHeader={secondNavBarHeader}
            thirdNavBarHeader={thirdNavBarHeader}
            fourthNavBarHeader={fourthNavBarHeader}
            fifthNavBarHeader={fifthNavBarHeader}
            sixthNavBarHeader={sixthNavBarHeader}
            seventhNavBarHeader={seventhNavBarHeader}
            handleToggleClick={handleToggleClick}
          />
        </div>
      </div>
    </nav>
  );
};

const megaMenuItems: MegaMenuItemSample[] = [
  {
    title: "Category 1",
    body: "",
    items: [
      {
        href: "/category1/item1",
        label: "Item 1",
      },
      {
        href: "/category1/item2",
        label: "Item 2",
      },
      {
        href: "/category1/item3",
        label: "Item 3",
      },
    ],
  },
  {
    title: "Category 2",
    body: "",
    items: [
      {
        href: "/category2/item1",
        label: "Item 1",
      },
      {
        href: "/category2/item2",
        label: "Item 2",
      },
      {
        href: "/category2/item3",
        label: "Item 3",
      },
    ],
  },
  {
    title: "Category 3",
    body: "",
    items: [
      {
        href: "/category3/item1",
        label: "Item 1",
      },
      {
        href: "/category3/item2",
        label: "Item 2",
      },
      {
        href: "/category3/item3",
        label: "Item 3",
      },
    ],
  },
];
const megaMenuItems2: MegaMenuItemSample[] = [
  {
    title: "Capabilities",
    body: "Ensign InfosSecurity offers the region's largest pure-play cybersecruity expertise and innovation",
    items: [
      {
        href: "/category1/item1",
        label: "Big Data Engineering",
      },
      {
        href: "/category1/item2",
        label: "Data Science",
      },
      {
        href: "/category1/item3",
        label: "Vulnerability Research",
      },
      {
        href: "/category1/item4",
        label: "Threat Research & Analysis",
      },
      {
        href: "/category1/item5",
        label: "Malware Analysis",
      },
    ],
  },
  {
    title: "Patents",
    body: "Ensign InfosSecurity offers the region's largest pure-play cybersecruity expertise and innovation",
    items: [
      {
        href: "/category2/item1",
        label: "AI/ML",
      },
      {
        href: "/category2/item2",
        label: "DGA",
      },
      {
        href: "/category2/item3",
        label: "Phishing",
      },
    ],
  },
  {
    title: "Innovations",
    body: "Ensign InfosSecurity offers the region's largest pure-play cybersecruity expertise and innovation",
    items: [
      {
        href: "/category3/item1",
        label: "Cyber Central Command System",
      },
      {
        href: "/category3/item2",
        label: "ATD (Helios)",
      },
      {
        href: "/category3/item3",
        label: "Global Sensors/HoneyPot",
      },
      {
        href: "/category3/item4 ",
        label: "DFIR (Artemis)",
      },
    ],
  },
  // {
  //   title: 'For Small & Medium Businesses (SMB)',
  //   body: "Ensign InfosSecurity offers the region's largest pure-play cybersecruity expertise and innovation",
  //   items: [
  //     {
  //       href: '/category3/item1',
  //       label: 'Cyber Central Command System'
  //     },
  //     {
  //       href: '/category3/item2',
  //       label: 'ATD (Helios)'
  //     },
  //     {
  //       href: '/category3/item3',
  //       label: 'Global Sensors/HoneyPot'
  //     },
  //     {
  //       href: '/category3/item4 ',
  //       label: 'DFIR (Artemis)'
  //     }
  //   ]
  // }
];
export default NavigationBar;
