export const BodyAndImage = (props) => {
  const titleColor = props.titleColor;
  const floatPosition =
    props.imageFloat === "float-right"
      ? "largephone:float-right"
      : "largephone:float-left";
  let imageWidth;
  let imageHeight;
  if (props.imageScale) {
    if (props.imageScale === "multiply") {
      imageWidth = props.image.width * props.imageSize;
      imageHeight = props.image.height * props.imageSize;
    }
    if (props.imageScale === "divide") {
      imageWidth = props.image.width / props.imageSize;
      imageHeight = props.image.height / props.imageSize;
    }
  } else {
    imageWidth = props.image.width;
    imageHeight = props.image.height;
  }

  return (
    <div>
      <div className={`${titleColor} flex header_font_size mb-4`}>
        {props.name}
      </div>
      <div
        className={`${floatPosition} float-none flex relative tablet:ml-3 tablet:mb-3`}
      >
        <div className="px-4">
          <img
            unoptimized={true}
            src={props.image}
            width={imageWidth}
            height={imageHeight}
            alt={props.name}
          />
        </div>
      </div>
      <div className="break-normal body_font_size">{props.body}</div>
    </div>
  );
};
