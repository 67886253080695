import React from "react";
import { PrimaryButton } from "../misc/Buttons";
import { useNavigate } from "react-router-dom";

export const CardWithNormalImage = (props) => {
  
  const {
    name,
    body,
    image,
    article_body,
    url,
    url_string,
  } = props.cardData;
  const navigate = useNavigate();
  return (
    <div className="flex flex-col largephone:flex-row py-4">
      <div className="largephone:w-4/12">
        <img src={image} alt={name} />
      </div>

      <div className="largephone:w-8/12">
        <div className="px-4">
          <p className="header_font_size">{name}</p>
          <p className="body_font_size">{body}</p>
          <div
            className="body_font_size"
            dangerouslySetInnerHTML={{ __html: article_body }}
          />
          <div className="pt-2">
            {url_string && (
              <PrimaryButton type="button" onClick={() => navigate(`${url}`)}>
                {url_string}
              </PrimaryButton>
            )}
            {/* {url_string_2 && (
                    <SecondaryButton
                      className="ml-4"
                      type="button"
                      hideChildren={true}
                    >
                      {url_string_2}
                    </SecondaryButton>
                  )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
