import React, { useEffect } from "react";
import { HalfSizeBanner } from "../../banners/HalfSizedBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { Footer } from "../../misc/Footer";
import { BodyAndImage } from "../../card/BodyAndImage";
import { Accordion } from "../../misc/Accordion";

import CloudBanner from "../../../assets/service listing/cloud/1920x300-CLOUD-SERVICES.png";
import CloudSmallBanner from "../../../assets/service listing/cloud/WEB-IMAGES-CLOUD (1).jpg";
import {
  CLOUD,
  CLOUD_BANNER,
  CLOUD_TITLE,
  CLOUD_BODY,
  WHAT_DOES_ENSIGN_OFFER,
  CLOUD_ACCORDION,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../../constant/text";

const Cloud = () => {
  useEffect(() => {
    document.title = CLOUD;
  }, []);

  return (
    <>
      <HalfSizeBanner
        name={CLOUD_BANNER}
        image={CloudBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="mx-4">
          <BodyAndImage
            name={CLOUD_TITLE}
            body={CLOUD_BODY}
            image={CloudSmallBanner}
            imageFloat="float-right"
          />
        </div>
        <div className="text-secondary-B200 header_font_size mx-4">
          {WHAT_DOES_ENSIGN_OFFER}
        </div>
        <div className="mx-4">
          {CLOUD_ACCORDION.map((accordion) => (
            <Accordion key={accordion.id} details={accordion} />
          ))}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default Cloud;
