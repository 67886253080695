import React, { useEffect } from "react";
import { HalfSizeBanner } from "../../banners/HalfSizedBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { Footer } from "../../misc/Footer";
import { BodyAndImage } from "../../card/BodyAndImage";
import { Accordion } from "../../misc/Accordion";

import DetectionBanner from "../../../assets/service listing/detection/1920x300-DETECTION.png";
import DetectionSmallBanner from "../../../assets/service listing/detection/WEB-IMAGES-DETECTION (1).jpg";
import {
  DETECTION,
  DETECTION_TITLE,
  DETECTION_BODY,
  WHAT_DOES_ENSIGN_OFFER,
  DETECTION_ACCORDION,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../../constant/text";

const Detection = () => {
  useEffect(() => {
    document.title = DETECTION;
  }, []);

  return (
    <>
      <HalfSizeBanner
        name={DETECTION}
        image={DetectionBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="mx-4">
          <BodyAndImage
            name={DETECTION_TITLE}
            body={DETECTION_BODY}
            image={DetectionSmallBanner}
            imageFloat="float-right"
          />
        </div>
        <div className="text-secondary-B200 header_font_size mx-4">
          {WHAT_DOES_ENSIGN_OFFER}
        </div>
        <div className="mx-4">
          {DETECTION_ACCORDION.map((accordion) => (
            <Accordion key={accordion.id} details={accordion} />
          ))}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default Detection;
