import React, { useEffect } from "react";
import { HalfSizeBanner } from "../banners/HalfSizedBanner";
import { BottomBanner } from "../banners/BottomBanner";
import { Footer } from "../misc/Footer";
import PrivacyBanner from "../../assets/privacy-terms/terms-banner.jpg";
import {
  WHISTLEBLOWINGPOLICY,
  WHISTLEBLOWINGPOLICY_BODY,
  WHISTLEBLOWINGPOLICY_DATA,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../constant/text";

const TermsOfUse = () => {
  useEffect(() => {
    document.title = WHISTLEBLOWINGPOLICY;
  }, []);
  return (
    <>
      <HalfSizeBanner
        name={WHISTLEBLOWINGPOLICY}
        body={WHISTLEBLOWINGPOLICY_BODY}
        image={PrivacyBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-2xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div
          className="break-normal phone:text-xl text-base mx-4"
          dangerouslySetInnerHTML={{
            __html: WHISTLEBLOWINGPOLICY_DATA.article_body,
          }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default TermsOfUse;
