import React, { useEffect } from "react";
import { HalfSizeBanner } from "../../banners/HalfSizedBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { Footer } from "../../misc/Footer";
import { CardWithImage } from "../../card/CardWithImage";

import SecureCloudBanner from "../../../assets/use-case/secure-cloud/1920x300-CLOUD.png";
import SecureCloudImg1 from "../../../assets/use-case/secure-cloud/OnPrem_ETCV2_page-0002.jpg";
import SecureCloudImg2 from "../../../assets/use-case/secure-cloud/etc image.png";

import {
  SECURECLOUD,
  SECURECLOUD_INFO_2,
  SECURECLOUD_BODY_1,
  SECURECLOUD_TITLE_1,
  SECURECLOUD_INFO,
  SECURECLOUD_FIGURE,
  SECURECLOUD_TITLE_2,
  SECURECLOUD_INFO_3,
  SECURECLOUD_FIGURE2,
  SECURECLOUD_TITLE_3,
  SECURECLOUD_TITLE_4,
  SECURECLOUD_BODY_2,
  SECURECLOUD_INFO_4,
  SECURECLOUD_FIGURE4,
  SECURECLOUD_INFO_5,
  SECURECLOUD_TITLE_5,
  SECURECLOUD_CARD,
  SECURECLOUD_BODY_3,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../../constant/text";

const SecureCloudJourney = () => {
  useEffect(() => {
    document.title = SECURECLOUD;
  }, []);
  return (
    <>
      <HalfSizeBanner
        name={SECURECLOUD}
        image={SecureCloudBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="flex body_font_size mx-4">{SECURECLOUD_INFO}</div>
        <div className="flex header_font_size mx-4">{SECURECLOUD_TITLE_1}</div>
        <div className="flex body_font_size mx-4">{SECURECLOUD_BODY_1}</div>
        <img src={SecureCloudImg1} alt={SECURECLOUD_INFO} />
        <div className="flex body_font_size mx-4">{SECURECLOUD_FIGURE}</div>
        <div className="flex body_font_size mx-4">{SECURECLOUD_INFO_2}</div>
        <div className="flex header_font_size mx-4">{SECURECLOUD_TITLE_2}</div>
        <div className="flex body_font_size mx-4">{SECURECLOUD_INFO_3}</div>
        <img src={SecureCloudImg2} alt={SECURECLOUD_INFO} />
        <div className="flex body_font_size mx-4">{SECURECLOUD_FIGURE2}</div>
        <div className="flex header_font_size mx-4">{SECURECLOUD_TITLE_3}</div>
        <div className="flex body_font_size mx-4">{SECURECLOUD_BODY_2}</div>
        <div className="flex header_font_size mx-4">{SECURECLOUD_TITLE_4}</div>
        <div className="flex body_font_size mx-4">{SECURECLOUD_INFO_4}</div>
        <div className="mx-4">
          <CardWithImage
            numInRow={3}
            rowIcons={SECURECLOUD_CARD}
            notToHaveBorder={true}
            centerChild={true}
            articleBody={true}
          />
        </div>
        <div className="flex body_font_size mx-4">{SECURECLOUD_FIGURE4}</div>
        <div className="flex header_font_size mx-4">{SECURECLOUD_TITLE_5}</div>
        <div
          className="flex body_font_size mx-4"
          dangerouslySetInnerHTML={{ __html: SECURECLOUD_INFO_5.article_body }}
        />
        <div className="flex text-xs mx-4">{SECURECLOUD_BODY_3}</div>
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default SecureCloudJourney;
