import React, { useEffect } from "react";
import { HalfSizeBanner } from "../banners/HalfSizedBanner";
import { BottomBanner } from "../banners/BottomBanner";
import { Footer } from "../misc/Footer";
import { ArticlesInsightsCard } from "../card/ArticlesInsightsCard";
import ResourcesBanner from "../../assets/resources/1920x300-RESOURCES.png";
import {
  RESOURCES,
  RESOURCES_BANNER_BODY,
  RESOURCESDATA,
  BOTTOM_BANNER_DETAILS,
  FOOTER_DETAILS,
} from "../../constant/text";

const Resources = () => {
  useEffect(() => {
    document.title = RESOURCES;
  }, []);

  return (
    <>
      <HalfSizeBanner
        name={RESOURCES}
        body={RESOURCES_BANNER_BODY}
        image={ResourcesBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-2xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <ArticlesInsightsCard numInRow={3} cardDetails={RESOURCESDATA} />
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default Resources;
