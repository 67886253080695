import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";

const CookieBanner = () => {
  const handleAccept = () => {
    Cookies.set("EnsignCookie", "true", { expires: 365 });
  };

  const handleDecline = () => {
    Cookies.set("EnsignCookie", "false", { expires: 365 });
  };

  const cookieConsent = Cookies.get("EnsignCookie");

  if (cookieConsent) {
    return null;
  }

  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="EnsignCookie"
        onAccept={handleAccept}
        onDecline={handleDecline}
        enableDeclineButton
        flipButtons
        style={{ background: "#2B373B", justifyContent: "center" }}
        buttonStyle={{ marginTop: "0px" }}
        contentStyle={{ flex: "0 0 100%", textAlign: "center" }}
      >
        <div className="desktop:text-xl largephone:text-lg text-sm">
          We use cookies to enhance your browsing experience. By clicking
          &apos;Accept&apos;, you consent to our use of cookies. You may also
          &apos;Decline&apos; to disable the cookies.
        </div>
      </CookieConsent>
    </div>
  );
};

export default CookieBanner;
