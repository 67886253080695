/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { PrimaryButton } from "../misc/Buttons";
import { Link } from "react-router-dom";

export const BottomBanner = (props) => {
  const { name, body, image, url, url_string } =
    props.bannerDataImage[0];
  return (
    <div>
      <div className="halfbanner__container">
        <img className="bottombanner__image" src={image} alt={name} />
        <div className={`bottombanner__text text-white`}>
          <div className="tablet:text-4xl mb-4 largephone:text-2xl text-base font-semibold">
            {name}
          </div>
          <h1 className="tablet:text-3xl largephone:text-xl text-sm mb-4">
            {body}
          </h1>
          <Link to={url}>
            <PrimaryButton
              type="button"
              className="text-sm tablet:text-xl"
              hideChildren={true}
            >
              {url_string}
            </PrimaryButton>
          </Link>
        </div>
      </div>
    </div>
  );
};
