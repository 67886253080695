import React, { useEffect } from "react";
import { HalfSizeBanner } from "../../banners/HalfSizedBanner";
import { BottomBanner } from "../../banners/BottomBanner";
import { CardWithTopImage } from "../../card/CardWithTopImage";
import { Footer } from "../../misc/Footer";
import TGPBanner from "../../../assets/tgp/1920x300-TGP.png";
import ApplicationImg from "../../../assets/tgp/TGP_Application_criteria.png";

import {
  TGP_WORDS,
  BOTTOM_BANNER_DETAILS,
  TGP_ROTATION,
  TGP_TITLE,
  TGP_BODY,
  TGP_ROTATION_CARD,
  TGP_APPLICATION,
  TGP_PROGRAMME_FAQ,
  TGP_APPLICTION_FAQ,
  TGP_FAQS,
  FOOTER_DETAILS,
} from "../../../constant/text";

const TGP = () => {
  useEffect(() => {
    document.title = TGP_WORDS;
  }, []);
  return (
    <>
      <HalfSizeBanner
        name={TGP_WORDS}
        image={TGPBanner}
        titleColor="text-white"
      />
      <div className="py-4 tablet:max-w-screen-2xl tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="header_font_size mx-4">{TGP_TITLE}</div>
        <div className="break-normal body_font_size mx-4 pt-4">{TGP_BODY}</div>
      </div>
      <div className="slant-line-container">
        <hr className="slant-line-blue-top" />
        <hr className="slant-line-bottom" />
      </div>
      <div className="py-4 tablet:max-w-screen-2xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <CardWithTopImage numInRow={2} rowIcons={TGP_ROTATION} />
      </div>
      <div className="slant-line-container">
        <hr className="slant-line-blue-top" />
        <hr className="slant-line-bottom" />
      </div>
      <div className="pb-2 max-w-screen-2xl tablet:mx-auto mx-2 gap-4 flex-wrap">
        <CardWithTopImage numInRow={3} rowIcons={TGP_ROTATION_CARD} />
        <div className="header_font_size mx-4 my-4">{TGP_APPLICATION}</div>
        <img unoptimized={true} src={ApplicationImg} alt={TGP_APPLICATION} />
      </div>
      <div className="tablet:max-w-screen-2xl mt-2 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="header_font_size">{TGP_FAQS}</div>
      </div>
      <div className="py-4 max-w-screen-2xl mx-auto tablet:flex tablet:flex-auto flex-wrap">
        <div className="card_header_font_size mx-4 pb-1">
          {TGP_PROGRAMME_FAQ.name}
        </div>
        <div
          className="break-normal body_font_size mx-4"
          dangerouslySetInnerHTML={{
            __html: TGP_PROGRAMME_FAQ.article_body,
          }}
        />
      </div>
      <div className="py-4 pb-4 max-w-screen-2xl mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <div className="card_header_font_size mx-4 pb-1">
          {TGP_APPLICTION_FAQ.name}
        </div>
        <div
          className="break-normal body_font_size mx-4"
          dangerouslySetInnerHTML={{
            __html: TGP_APPLICTION_FAQ.article_body,
          }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <BottomBanner bannerDataImage={BOTTOM_BANNER_DETAILS} />
      </div>
      <div className="py-4 tablet:max-w-screen-xl mx-4 tablet:mx-auto tablet:flex tablet:flex-auto gap-4 flex-wrap">
        <Footer footerLinksData={FOOTER_DETAILS} />
      </div>
    </>
  );
};

export default TGP;
