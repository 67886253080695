import React from "react";

const SmallLoader = ({ className }) => (
  <i className={`fas fa-circle-notch fa-spin text-white ${className || ""}`} />
);

const CubeLoader = ({ className }) => (
  <div className={`cube ${className || ""}`}>
    <div className="sides">
      <div className="top"></div>
      <div className="right"></div>
      <div className="bottom"></div>
      <div className="left"></div>
      <div className="front"></div>
      <div className="back"></div>
    </div>
  </div>
);

const FormLoader = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <div className="mt-14 mb-10 text-center">
        <SmallLoader />
      </div>
    );
  }

  return <>{children}</>;
};

export { FormLoader, SmallLoader, CubeLoader };
